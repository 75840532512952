import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from '@ti/core/core.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';

import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';

import {TiScrollToService} from '@ti/ti-scroll-to.service';
import {DeviceDetectorModule} from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    InfiniteScrollModule,
    DeviceDetectorModule,
    ToastrModule.forRoot(),
    ScrollToModule.forRoot()
  ],
  providers: [TiScrollToService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
