import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TiScrollToService } from '@ti/ti-scroll-to.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SectionsService } from './sections.service';

@Component({
  selector: 'ti-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  menu: any;
  // positions = [];
  // sizes = [];
  sessions = [
    'ti-customer-banner',
    'ti-customer-needs',
    'ti-features',
    'ti-service-packages',
    'ti-outstanding',
    'ti-carousel',
  ];
  title = 'angular landing';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private scrollTo: TiScrollToService,
    private sectionsService: SectionsService,
  ) {
  }

  ngOnInit() {
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.translate.use(lang);
    } else {
      localStorage.setItem('lang', 'en');
      this.translate.use('en');
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      } else if (event instanceof RoutesRecognized) {
        // Router parses the URL and the routes are recognized.
      } else if (event instanceof RouteConfigLoadStart) {
        // Before the Router lazyloads a route configuration.
      } else if (event instanceof RouteConfigLoadEnd) {
        // Route has been lazy loaded.
      } else if (event instanceof NavigationEnd) {
        // Navigation Ended Successfully.
      } else if (event instanceof NavigationCancel) {
        // Navigation is canceled as the Route-Guard returned false during navigation.
      } else if (event instanceof NavigationError) {
      }
    });
  }

  highlightMenu(position) {
    let anchor = 120;
    if (window.innerWidth > window.innerHeight) {
      anchor = 20;
    } else {
      if (window.innerWidth < 376) {
        anchor = 30;
      }
    }
    const menu = this.menu ? this.menu : document.getElementById('ti-navbar');
    if (position > anchor) {
      menu.classList.add('merge');
    } else {
      menu.classList.remove('merge');
    }
  }

  scrolled(pos) {
    this.highlightMenu(pos);

    this.sectionsService.sections.forEach((section, index) => {
      if (
        section.element?.nativeElement.getBoundingClientRect().y / window.innerHeight <= 0.3 &&
        this.scrollTo.currentView < index + 1
      ) {
        this.scrollTo.currentView = index;
      }

      if (pos + window.innerHeight >= document.getElementById('ti-main').scrollHeight) {
        this.scrollTo.currentView = this.sessions.length - 1;
      }
    });
  }

  scrolledUp(pos) {
    this.highlightMenu(pos);

    this.sectionsService.sections.forEach((section, index) => {
      if (pos === 0) {
        this.scrollTo.currentView = 0;
      } else if (
        section.element?.nativeElement?.getBoundingClientRect().y / window.innerHeight >= -0.3 &&
        this.scrollTo.currentView > index
      ) {
        console.log('index');

        this.scrollTo.currentView = index;
      }
    });
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    // 	for (const item of sessions) {
    // 		const el = document.getElementById(item);
    // 		this.positions.push(el.offsetTop);
    // 		this.sizes.push(el.getBoundingClientRect().height);
    // 	}
    // 	if (this.deviceService.isDesktop()) {
    // 		document.getElementById('ti-main').addEventListener(
    // 			'wheel',
    // 			e => {
    // 				if (e.deltaY < 0) {
    // 					if (this.scrollTo.currentView > 0) {
    // 						this.scrollTo.currentView--;
    // 					}
    // 				} else if (e.deltaY > 0) {
    // 					if (this.scrollTo.currentView < this.positions.length - 1) {
    // 						this.scrollTo.currentView++;
    // 					}
    // 				}
    // 				this.scrollTo.triggerScrollTo(
    // 					sessions[this.scrollTo.currentView],
    // 					-(window.innerHeight - this.sizes[this.scrollTo.currentView]) / 2 - 50,
    // 				);
    // 			},
    // 			{ passive: false },
    // 		);
    // 	}
    // }, 400);
  }
}
