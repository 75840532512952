import {Injectable} from '@angular/core';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';

@Injectable()
export class TiScrollToService {

  currentView: number;

  constructor(private scrollToService: ScrollToService) {
    this.currentView = 0;
  }

  triggerScrollTo(target, offset) {

    const config: ScrollToConfigOptions = {target, offset};

    this.scrollToService.scrollTo(config);
  }
}
